import React from 'react';

const { createContext, useContext, useState, useEffect } = React;

export const GlobalConfigContext = createContext({});
GlobalConfigContext.displayName = 'GlobalConfig';

export const GlobalConfigProvider = (props) => {
  const { children, fonts, UA } = props;

  const isMobile = UA ? UA.device.type === 'mobile' : false;
  const isIOS = UA ? UA.os.name === 'iOS' : false;
  const isSafari = UA
    ? UA.browser.name === 'Safari' || UA.browser.name === 'Mobile Safari'
    : false;
  const device = {
    isMobile,
    isIOS,
    isSafari,
  };

  return (
    <GlobalConfigContext.Provider
      value={{
        fonts,
        device,
      }}
    >
      {children}
    </GlobalConfigContext.Provider>
  );
};

export const useGlobalConfig = () => {
  return useContext(GlobalConfigContext);
};
