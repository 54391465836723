import React from 'react';
import Modal from 'react-modal';

import { useGlobalConfig } from 'contexts/GlobalConfig';
import { useUserAuth } from 'contexts/UserAuth';
import { CrossSvg } from 'components/SorraSvg';
import {
  shouldShowAddToHomeScreenPopup,
  setShouldShowAddToHomeScreenPopupTimestamp,
} from 'utils/localStore';

const { useState, useEffect } = React;

const AddToHomeModal = (props) => {
  const { device } = useGlobalConfig();
  const { isSignInStatusReady, user } = useUserAuth();

  const { isMobile, isIOS, isSafari } = device;

  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const shareIcon = isIOS ? (isSafari ? 'safari' : 'ios') : 'android';

  const handleClose = () => {
    if (isSelected) {
      setShouldShowAddToHomeScreenPopupTimestamp('never');
    } else {
      const oneMonthMinSecs = 1000 * 60 * 60 * 24 * 30;
      setShouldShowAddToHomeScreenPopupTimestamp(Date.now() + oneMonthMinSecs);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isMobile && isSignInStatusReady && user) {
      setTimeout(() => {
        const shouldShow = shouldShowAddToHomeScreenPopup();
        setIsOpen(shouldShow);
      }, 1000);
    }
  }, [isMobile, isSignInStatusReady, user]);

  return (
    isMobile &&
    isSignInStatusReady &&
    user && (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => {
          // setIsOpen(false);
        }}
        shouldCloseOnOverlayClick={false}
        className="relative w-full max-w-[320px] rounded-2xl bg-white py-6 focus:outline-none focus:ring-0"
        overlayClassName="z-50 absolute flex items-center justify-center top-0 left-0 right-0 bottom-0 w-full h-full bg-[#111928] bg-opacity-50"
      >
        <button
          className="absolute right-4 top-4 h-5 w-5 cursor-pointer"
          onClick={() => {
            handleClose();
          }}
        >
          <CrossSvg color="#4A4A4A" />
        </button>
        <div className="flex w-full flex-col items-center">
          <div className="h-12 w-12 overflow-hidden rounded-2xl">
            <img
              src="/images/appleTouchIcon.png"
              className="h-full w-full object-contain"
            />
          </div>

          <p className="mt-4.5 text-lg font-medium">將Sorra添加至主畫面</p>
          <p className="mt-2 text-sm text-[#4A4A4A]">🥳 下次找產品更方便！</p>
          <div className="mt-4 flex items-center gap-x-1.5">
            <input
              className="h-[14px] w-[14px] cursor-pointer rounded-[4px] text-sorra-pink focus:ring-0"
              type="checkbox"
              checked={isSelected}
              onChange={(value) => {
                setIsSelected(!isSelected);
              }}
            />
            <p className="text-sm text-[#9B9B9B]">以後不要再提示</p>
          </div>
          <div className="my-[18px] w-full border border-[#E7E7E7]"></div>
          <div className="flex items-center gap-x-1 text-sm text-[#111928]">
            <p>點擊</p>
            <div className="h-5 w-6">
              <img
                src={`/images/share_${shareIcon}.png`}
                className="h-full w-full object-contain"
              />
            </div>
            <p>並「加入主畫面」</p>
          </div>
        </div>
      </Modal>
    )
  );
};

export default AddToHomeModal;
