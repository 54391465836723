import _ from 'lodash';

export const getSorraSearchHistory = () => {
  let history = [];
  try {
    const h = window.localStorage.getItem('sorra_search_history') || '[]';
    history = JSON.parse(h);
  } catch (_) {
    history = [];
  }
  return history;
};

export const addSorraSearchHistory = (keyword) => {
  let history = getSorraSearchHistory();
  const nk = _.trim(keyword);
  history = _.uniq([nk, ...history]);
  window.localStorage.setItem('sorra_search_history', JSON.stringify(history));
};

export const deleteSorraSearchHistory = () => {
  window.localStorage.removeItem('sorra_search_history');
};

export const shouldShowAddToHomeScreenPopup = () => {
  try {
    const showAddToHomeScreenPopup = window.localStorage.getItem(
      'sorra_add_to_home_popup'
    );
    if (showAddToHomeScreenPopup === 'never') {
      return false;
    }
    if (
      !_.isEmpty(showAddToHomeScreenPopup) &&
      !_.isNaN(_.toNumber(showAddToHomeScreenPopup))
    ) {
      const now = new Date();
      const expired = new Date(_.toNumber(showAddToHomeScreenPopup));
      if (now < expired) {
        return false;
      }
    }
  } catch (e) {
    console.log(e);
  }
  window.localStorage.removeItem('sorra_add_to_home_popup');
  return true;
};

export const setShouldShowAddToHomeScreenPopupTimestamp = (timestamp) => {
  window.localStorage.setItem('sorra_add_to_home_popup', timestamp);
};

export default {
  addSorraSearchHistory,
  getSorraSearchHistory,
  deleteSorraSearchHistory,
  shouldShowAddToHomeScreenPopup,
  setShouldShowAddToHomeScreenPopupTimestamp,
};
